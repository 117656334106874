* {
  font-family: "Overpass", sans-serif !important;
  font-size: 11pt !important;
}

.caseDetailHitSelectionTableContainer #divider {
  width: 100%;
}

.tableHitResultsHeader {
  background-color: #f1f7fdff !important;
}

.MuiTableRow-root .MuiTableCell-root {
  color: #003671 !important;
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
  font-weight: bold !important;
}

.hlHitResult {
  color: #003671;
  font-weight: bold;
  font-size: 14pt !important;
}

.lastMatchedDate {
  position: absolute;
  right: 15px;
  font-size: 10pt !important;
}

.hlHitProduct {
  color: #003671;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 12pt !important;
}

.productTable {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: none;
}

.productTable::-webkit-scrollbar {
  display: none;
}

.hitResultRowStandard {
  width: 20%;
}

.hitResultRowSmaller {
  width: 6%;
}

.hitResultRowCheckBox {
  width: 2%;
  max-width: 60px;
}

.hitResultsCSP,
.hitResultsDSP,
.hitResultsTIP {
  margin-bottom: 20px;
}

.hitGesperrt .MuiTableCell-root {
  text-align: center;
}

.toolTipText {
  color: #003671;
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  background-color: white !important; /* Background color */
}

.MuiTooltip-tooltip {
  background-color: white !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
