.divOverviewTableContainer {
  height: 650px !important;
  overflow: hidden;
}

.divOverviewTableContainer .MuiDataGrid-root {
  border: none;
  overflow: hidden;
}

.data-grid .MuiDataGrid-cell:focus,
.data-grid .MuiDataGrid-cell:focus-within,
.data-grid .MuiDataGrid-columnHeader:focus,
.data-grid .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.divOverviewTableContainer .MuiDataGrid-columnHeaders {
  background-color: #3f51b5 !important;
  font-size: 10pt;
  font-family: "Overpass", sans-serif;
  max-height: 40px;
}

.divOverviewTableContainer .MuiDataGrid-columnHeader {
  background-color: #f1f7fdff !important;
  color: #003670ff !important;
  max-height: 40px;
}

.divOverviewTableContainer .MuiDataGrid-columnHeaderTitle {
  color: #003670ff !important;
}

.divOverviewTableContainer .MuiDataGrid-virtualScroller {
  scrollbar-width: none; /* für Firefox */
}

.divOverviewTableContainer .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none; /* für Webkit-Browser */
}

#productHitCount {
  display: block;
}
#hit,
#hitPending,
#multipleHit,
#noHit,
#apiError{
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 7pt !important;
  font-weight: bold !important;
  margin-right: 8px;
}

#hit {
  background-color: #229954;
}

#noHit {
  border: 1px solid #229954 !important;
  color: #229954;
  padding: 4px;
}

#multipleHit {
  background-color: #229954;
  margin-right: 0;
}

#hitPending {
  background-color: #7f8c8d;
}

#hitCounter {
  background-color: #003671;
  border-radius: 5px;
  padding: 1px;
  color: #ffffff;
  font-size: 8pt !important;
  top: -9px;
  right: 8px;
  position: relative;
  margin-right: -5px;
}
#apiError {
  background-color: #f44336;
  color: white;
  border: 1px solid #f44336 !important;
}

.divOverviewFooter {
  display: flex;
  justify-content: right;
  padding: 10px 0;
}

.MuiDataGrid-row .warningDueSoon {
  color: #e7807f;
}

.MuiDataGrid-row .warningComingSoon {
  color: #ffd580;
}

.dueDataAndWarning {
  display: flex;
  align-items: center;
}

.dueDate {
  margin-right: 8px; /* Adjust margin as needed */
}

.nameColumn {
  font-weight: bold;
  color: #003671;
}

#statusCellNew,
#statusCellOpen,
#statusCellInProgress,
#statusCellInInvestigation,
#statusCellReadyToSend,
#statusCellDone {
  color: white;
  padding: 7px;
  border-radius: 5px;
  font-size: 7pt !important;
  font-weight: bold !important;
}

#statusCellNew {
  background-color: #e74c3c;
}

#statusCellOpen {
  background-color: #f1c40f;
}

#statusCellInProgress {
  background-color: #34495e;
}

#statusCellInInvestigation {
  background-color: #7f8c8d;
}

#statusCellReadyToSend {
  background-color: #3498db;
}

#statusCellDone {
  background-color: #229954;
}

.divOverviewTableContainer {
  position: relative;
  height: 100%;
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px; /* Adjust width as needed */
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
  padding: 20px;
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
}

.progressBarWrapper {
  margin-bottom: 10px;
}

.progressBar {
  width: 100%;
}

.progressText {
  color: #666;
  font-size: 14px;
}
