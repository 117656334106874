/* General font and size for all elements */
* {
  font-family: "Overpass", sans-serif !important;
  font-size: 11pt !important;
  box-sizing: border-box;
}
.divCDPersonaContainer {
  justify-content: center;
}

/* MUI components */
.MuiTextField-root,
.MuiInputBase-root,
.MuiFormControl-root,
.MuiButtonBase-root,
.MuiMenuItem-root,
.MuiTypography-root {
  font-size: 10pt !important;
  color: #003671;
}

.MuiInputLabel-root,
.MuiInputBase-root,
.MuiMenuItem-root {
  color: #003671 !important;
}

.sectionPersona .MuiInputBase-root,
.sectionAddress .MuiInputBase-root,
.sectionPreAddress .MuiInputBase-root {
  background-color: rgb(250, 250, 250) !important;
}

/* Headings */
.hlPersonData,
.hlAddressData {
  color: #003671;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 12pt !important;
}

.sectionPersona,
.sectionAddress,
.sectionPreAddress {
  margin-bottom: 20px;
}

.personDataRow1,
.personDataRow2,
.personDataRow3,
.personDataRow4 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.addressDataRow1,
.addressDataRow2 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.preAddressDataRow1,
.preAddressDataRow2 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sectionPreAddress {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.preAddress1,
.preAddress2 {
  flex: 1;
}

.tFPersona,
.tFAddress,
.tFPreAddress1,
.tFPreAddress2 {
  flex: 1;
}

.sectionPersona .personDataRow1 #sBGender {
  width: 150px;
}
.sectionPersona .personDataRow1 #sBTitle {
  width: 150px;
}
.sectionPersona .personDataRow3 #dfBirthDate {
  width: 170px;
}
.sectionAddress .addressDataRow1 #tFAddressHouseNo {
  width: 80px;
}
.sectionAddress .addressDataRow2 #tFAddressZIP {
  width: 60px;
}
.sectionPreAddress .preAddressDataRow1 #tFPreAddress1HouseNo {
  width: 60px;
}
.sectionPreAddress .preAddressDataRow2 #tFPreAddress1ZIP {
  width: 60px;
}
.sectionPreAddress .preAddressDataRow1 #tFPreAddress2HouseNo {
  width: 60px;
}
.sectionPreAddress .preAddressDataRow2 #tFPreAddress2ZIP {
  width: 60px;
}

.sectionPersonDataConfirmation {
  display: flex;
  gap: 15px;
}

.cmdSaveCase {
  background-color: #003671 !important;
  color: white !important;
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cmdSaveCase:disabled {
  background-color: #d3d3d3 !important; /* Light gray for disabled */
  color: #808080 !important;           /* Muted gray for text */
  cursor: not-allowed !important;      /* Show "not-allowed" cursor */
  opacity: 0.8 !important;             /* Slightly translucent */
  border: 1px solid #c0c0c0 !important; /* Optional subtle border */
}

.progressIndicator {
  position: absolute; /* Position it within the form */
  top: 58%; /* Center it vertically */
  left: 50%; /* Center it horizontally */
  transform: translate(-50%, -50%); /* Adjust position to be truly centered */
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cmdDiscardChanges {
  color: #003671 !important;
  font-size: 10pt !important;
  font-family: "Overpass", sans-serif !important;
  text-transform: none !important;
}
