/* General form container */
.divOnlineFormDataContainer {
    display: flex;                /* Use flexbox for alignment */
    flex-direction: column;       /* Stack items vertically */
    align-items: center;          /* Center items horizontally */
    border-radius: 8px;           /* Optional: rounded corners */
    margin: auto;                 /* Center the form horizontally */
}

/* Form container width */
.formContainer {
    width: 95vw;                  /* Volle Breite des Viewports */
    max-width: 1100px;           /* Increase maximum width for the form */
    margin: 0 auto;               /* Center the form horizontally */
    background-color: white;      /* Set a background color */
    border-radius: 8px;           /* Optional: add rounded corners */
}

/* Form heading */
.divOnlineFormDataHeading {
    font-size: 18px !important;   /* Font size for the heading */
    color: #003671;               /* Change text color as per your design */
    margin-bottom: 20px;          /* Space below the heading */
    text-align: center;           /* Center the text */
}

/* Checkbox container */
.checkboxContainer {
    display: flex;               /* Use flexbox for checkbox container */
    justify-content: center;     /* Center the checkboxes horizontally */
    gap: 20px;                   /* Space between checkboxes */
    width: 100%;                 /* Make sure it takes full width */
    margin-bottom: 20px;         /* Space below checkbox container */
}

/* Checkbox item */
.checkboxItem {
    display: flex;               /* Align label and checkbox horizontally */
    align-items: center;         /* Center align items vertically */
}

/* Personal info section */
.sectionUserInfo, .sectionLocationInfo {
    width: 100%;                 /* Ensure sections take full width */
    background-color: #f1f7fd;   /* Light blue background color */
    padding: 20px;               /* Padding inside the section */
    border-radius: 8px;          /* Rounded corners */
    margin-bottom: 24px;         /* Space below each section */
    max-width: 1400px;           /* Set wider max-width */
}

/* Row container for form inputs */
.userDataRow1, .userDataRow2, .userDataRow3, .addressRow1, .addressRow2 {
    display: flex;               /* Use flexbox for field rows */
    gap: 16px;                   /* Space between fields */
    margin-bottom: 16px;         /* Space below each row */
}

/* Input fields */
.genderSelect, .firstNameField, .lastNameField, .birthNameField,
.emailField, .previousNameField1, .previousNameField2,
.streetField, .zipField, .cityField, .countryField {
    flex: 1;                     /* Allow fields to grow */
    min-width: 220px;            /* Minimum width for fields */
    background-color: white;     /* Set background color of fields to white */
    border: 1px solid #ccc;      /* Light border for fields */
    border-radius: 4px;          /* Rounded corners */
}

/* Custom width for specific fields */
.birthNameField {
    min-width: 130px;            /* Reduced minimum width for the birth date field */
    background-color: white;
    flex: 1.1;
}

/* Make birth date field narrower */
.birthDateField {
    min-width: 130px;            /* Further reduced minimum width for the birth date field */
    background-color: white;
    flex: 0.8;                   /* Reduce flex to make it narrower */
}

/* Make house number field narrower */
.houseNoField {
    min-width: 80px;             /* Reduced minimum width for the house number field */
    background-color: white;
    flex: 0.2;                   /* Smaller flex to make it narrower */
}

/* Make email field wider */
.emailField {
    min-width: 400px;            /* Increased minimum width for the email field */
    flex: 2;                     /* Larger flex to make it wider */
    background-color: white;
}

.streetField, .houseNoField, .zipField, .cityField, .countryField {
    background-color: white !important;
}

/* Section headers */
.headerUserData, .headerAddressData {
    display: flex;
    color: #003671;
    justify-content: center;
    margin-bottom: 14px;
}

.headerUserData svg, .headerAddressData svg {
    margin-right: 8px; /* Space between the icon and text */
    width: 24px; /* Adjust icon size */
    height: 24px; /* Ensure consistent icon size */
}

.personText, .addressText {
    font-size: 16pt !important;
}

/* Submit button */
.buttonContainer {
    display: flex;
    justify-content: center !important;
}

.antragSendenButton {
    background-color: #003671 !important;
    font-size: 10pt !important;
    font-family: "Overpass", sans-serif !important;
    text-transform: none !important;
    text-align: center !important;
    color: white !important;
}

/* Snackbar message customization */
.snackbarMessage {
    font-size: 14px;
    color: white;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
}

.shake {
    animation: shake 0.3s;
}

.checkboxDataProtectionContainer {
    display: flex;
    color: #003671;
    align-items: center;
    gap: 8px;
}

.turnstile-container {
    text-align: center;
    margin-bottom: 20px;
}
